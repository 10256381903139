/**
 * Created by aheshperera
 * 12/3/18
 * */

/**
 * Action Types
 */
export const REQUEST_832_FILES = 'REQUEST_832_FILES';
export const RECEIVE_832_FILES = 'RECEIVE_832_FILES';

export const REQUEST_VENDOR_ASSOCIATION_FILE_IMPORT = 'REQUEST_VENDOR_ASSOCIATION_FILE_IMPORT';
export const RECEIVE_VENDOR_ASSOCIATION_FILE_IMPORT = 'RECEIVE_VENDOR_ASSOCIATION_FILE_IMPORT';

export const REQUEST_MASS_AGREEMENT_FILE_IMPORT = 'REQUEST_MASS_AGREEMENT_FILE_IMPORT';
export const RECEIVE_MASS_AGREEMENT_FILE_IMPORT = 'RECEIVE_MASS_AGREEMENT_FILE_IMPORT';

export const REQUEST_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT = 'REQUEST_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT';
export const RECEIVE_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT = 'RECEIVE_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT';

export const REQUEST_VENDOR_FILES_PROCESS_STATUS = 'REQUEST_VENDOR_FILES_PROCESS_STATUS';
export const RECEIVE_VENDOR_FILES_PROCESS_STATUS = 'RECEIVE_VENDOR_FILES_PROCESS_STATUS';

export const CLEAR_CURRENT_VENDOR_FILES_LIST = 'CLEAR_CURRENT_VENDOR_FILES_LIST';
export const CLEAR_MASS_AGREEMENT_FILES_LIST = 'CLEAR_MASS_AGREEMENT_FILES_LIST';
export const CLEAR_VENDOR_ASSOCIATION_FILES_LIST = 'CLEAR_VENDOR_ASSOCIATION_FILES_LIST';

/**
 * Actions
 */
export const request832Files = () => ({ type: REQUEST_832_FILES });
export const receive832Files = (data) => ({ type: RECEIVE_832_FILES, data });

export const clearCurrentVendorFilesList = () => ({ type: CLEAR_CURRENT_VENDOR_FILES_LIST });
export const clearCurrentMassAgreementsFilesList = () => ({ type: CLEAR_MASS_AGREEMENT_FILES_LIST });
export const clearCurrentVendorAssociationFilesList = () => ({ type: CLEAR_VENDOR_ASSOCIATION_FILES_LIST });

export const requestVendorAssociationFileImport = (type, files) => (
  {
    type: REQUEST_VENDOR_ASSOCIATION_FILE_IMPORT,
    data: {
      type, files
    }
  });
export const receiveVendorAssociationFileImport = (data) => ({ type: RECEIVE_VENDOR_ASSOCIATION_FILE_IMPORT, data });

export const requestMassAgreementsImport = (type, files) => (
  {
    type: REQUEST_MASS_AGREEMENT_FILE_IMPORT,
    data: {
      type, files
    }
  });
export const receiveMassAgreementsImport = (data) => ({ type: RECEIVE_MASS_AGREEMENT_FILE_IMPORT, data });

export const requestVendorFileReimportUsingVariationReport = (variationDetails) => (
  { type: REQUEST_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT, data: { variationDetails } });

export const receiveVendorFileReimportUsingVariationReport = (data) => ({
  type: RECEIVE_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT,
  data
});

export const receiveVendorFilesProcessStatus = (data) => (
  { type: RECEIVE_VENDOR_FILES_PROCESS_STATUS, data });
