import { PERMISSIONS } from './helpers/constants';

export default {
  items: [
    {
      name: 'Dashboard',
      url: '/',
      icon: 'icon-speedometer',
      attributes: { id: 'app_sidebar_nav_dashboard_link' },
      permissions: [PERMISSIONS.VIEW_COST_DATA_MANAGEMENT_DASHBOARD]
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'Vendor File Management',
      style: { id: 'app_sidebar_nav_vendor_file_import_title' },
      display: 'none',
      permissions: [
        PERMISSIONS.VIEW_EXPLORER,
        PERMISSIONS.VIEW_PCP_EXPLORER,
        PERMISSIONS.VIEW_FILE_TRANSFER_LOG,
        PERMISSIONS.VIEW_BIS_FILE_EXPLORER,
        PERMISSIONS.IMPORT_VENDOR_COST_FILE,
        PERMISSIONS.CREATE_VARIATION_REPORT,
      ]
    },
    {
      name: 'Explorer',
      url: '/file-explorer',
      icon: 'fa fa-search',
      attributes: { id: 'app_sidebar_nav_file_explorer_link' },
      permissions: [PERMISSIONS.VIEW_EXPLORER],
    },
    {
      name: 'Transfer Log',
      url: '/file-transfer-details',
      icon: 'fa fa-exchange',
      attributes: { id: 'app_sidebar_nav_file_transfer_details_link' },
      permissions: [PERMISSIONS.VIEW_FILE_TRANSFER_LOG],
    },
    {
      name: 'Explorer - PC Pricing',
      icon: 'fa fa-search',
      permissions: [PERMISSIONS.VIEW_PCP_EXPLORER],
      children: [
        {
          name: 'Vendor Cost',
          url: '/file-explorer-pcp/vendor-cost',
          attributes: { id: 'app_sidebar_nav_pcp_cost_file_explorer_link' },
        },
        {
          name: 'Vendor Agreements',
          url: '/file-explorer-pcp/vendor-agreements',
          attributes: { id: 'app_sidebar_nav_pcp_agreements_file_explorer_link' },
        }
      ]
    },
    {
      divider: true,
      permissions: [
        PERMISSIONS.EXECUTE_COST_AUDIT,
        PERMISSIONS.EXECUTE_LIMITED_COST_AUDIT,
        PERMISSIONS.EXECUTE_COST_FILE,
        PERMISSIONS.EXECUTE_LIMITED_COST_FILE,
      ]
    },
    {
      title: true,
      name: 'Reports',
      style: { id: 'app_sidebar_reports_section_title' },
      display: 'none',
      permissions: [
        PERMISSIONS.EXECUTE_COST_AUDIT,
        PERMISSIONS.EXECUTE_LIMITED_COST_AUDIT,
      ]
    },
    {
      name: 'Cost Audit',
      url: '/reports/cost-audit',
      icon: 'fa fa-newspaper-o',
      attributes: { id: 'app_sidebar_nav_cost_audit_reports_link' },
      permissions: [PERMISSIONS.EXECUTE_COST_AUDIT, PERMISSIONS.EXECUTE_LIMITED_COST_AUDIT]
    },
    {
      divider: true,
      permissions: [
        PERMISSIONS.EXECUTE_COST_FILE,
        PERMISSIONS.EXECUTE_LIMITED_COST_FILE,
        PERMISSIONS.INITIATE_ADHOC_COST_CHANGE,
        PERMISSIONS.INITIATE_CATALOG_COST_CHANGE
      ]
    },
    {
      title: true,
      name: 'Cost Maintenance',
      style: { id: 'app_sidebar_cost_maintenance_section_title' },
      display: 'none',
      permissions: [
        PERMISSIONS.EXECUTE_COST_FILE,
        PERMISSIONS.EXECUTE_LIMITED_COST_FILE,
        PERMISSIONS.INITIATE_ADHOC_COST_CHANGE,
        PERMISSIONS.INITIATE_CATALOG_COST_CHANGE
      ]
    },
    {
      name: 'Cost File',
      url: '/cost-maintenance/cost-file',
      icon: 'fa fa-file-text-o',
      attributes: { id: 'app_sidebar_nav_cost_file_link' },
      permissions: [PERMISSIONS.EXECUTE_COST_FILE, PERMISSIONS.EXECUTE_LIMITED_COST_FILE]
    },
    {
      name: 'Adhoc Cost Change',
      url: '/cost-maintenance/adhoc-cost-change',
      icon: 'fa fa-tags',
      attributes: { id: 'app_sidebar_nav_adhoc_cost_change_link' },
      permissions: [PERMISSIONS.INITIATE_ADHOC_COST_CHANGE]
    },
    {
      name: 'Catalog Cost Change',
      url: '/cost-maintenance/catalog-cost-change',
      icon: 'fa fa-book',
      attributes: { id: 'app_sidebar_nav_catalog_cost_change_link' },
      permissions: [PERMISSIONS.INITIATE_CATALOG_COST_CHANGE]
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'Agreement Management',
      style: { id: 'app_sidebar_nav_agreement_management_title' },
      display: 'none',
      permissions: [PERMISSIONS.IMPORT_AGREEMENT_REQUEST],
    },
    {
      name: 'Agreement Request Import',
      url: '/agreement-import',
      icon: 'fa fa-upload',
      attributes: { id: 'app_sidebar_nav_mass_agreement_import_link' },
      permissions: [PERMISSIONS.IMPORT_AGREEMENT_REQUEST],
    },
    {
      name: 'Base 12 Request Import',
      url: '/base12-import',
      icon: 'fa fa-upload',
      attributes: { id: 'app_sidebar_nav_base12_import_link' },
      permissions: [PERMISSIONS.IMPORT_AGREEMENT_REQUEST],
    },
    {
      name: 'SAS / FD Maintenance',
      url: '/sas-fd-maintenance',
      icon: 'fa fa-upload',
      attributes: { id: 'app_sidebar_nav_sas_fd_maintenance_link' },
      permissions: [PERMISSIONS.VIEW_SAS_FD],
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'User Management',
      style: { id: 'app_sidebar_nav_user_management_title' },
      display: 'none',
      permissions: [PERMISSIONS.VIEW_USER_DIRECTORY, PERMISSIONS.USER_MAINTENANCE]
    },
    {
      name: 'User Directory',
      url: '/user-management/user-directory',
      icon: 'fa fa-address-book',
      attributes: { id: 'app_sidebar_nav_user_directory_link' },
      permissions: [PERMISSIONS.VIEW_USER_DIRECTORY]
    },
    {
      name: 'Profile Management',
      url: '/user-management/profile-management',
      icon: 'fa fa-users',
      attributes: { id: 'app_sidebar_nav_user_profile_management_link' },
      permissions: [PERMISSIONS.USER_MAINTENANCE, PERMISSIONS.VIEW_USER_DIRECTORY]
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'Administration',
      style: { id: 'app_sidebar_admin_section_title' },
      display: 'none',
      permissions: [PERMISSIONS.VIEW_BIS_FILE_EXPLORER]
    },
    {
      name: 'BIS Files',
      url: '/bis-files',
      icon: 'icon-doc',
      attributes: { id: 'app_sidebar_nav_bis_file_explorer_link' },
      permissions: [PERMISSIONS.VIEW_BIS_FILE_EXPLORER]
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'Search',
      style: { id: 'app_sidebar_search_section_title' },
      display: 'none',
      permissions: [PERMISSIONS.VIEW_COST_DATA_MANAGEMENT_DASHBOARD]
    },
    {
      name: 'Workspace Search',
      url: '/search/workspace-search',
      icon: 'fa fa-search',
      attributes: { id: 'app_sidebar_nav_workspace_search_link' },
      permissions: [PERMISSIONS.VIEW_COST_DATA_MANAGEMENT_DASHBOARD]
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'Enterprise Cost',
      style: { id: 'app_sidebar_enterprise_cost_title' },
      display: 'none',
      permissions: [PERMISSIONS.VIEW_ENTERPRISE_COST_DATA]
    },
    {
      name: 'View',
      url: '/enterprise-cost-view',
      icon: 'fa fa-newspaper-o',
      attributes: { id: 'app_sidebar_nav_enterprise_cost_view_link' },
      permissions: [PERMISSIONS.VIEW_ENTERPRISE_COST_DATA]
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'Utilities',
      style: { id: 'app_sidebar_utilities_title' },
      display: 'none',
      permissions: [PERMISSIONS.VIEW_UTILITIES]
    },
    {
      name: 'Mapping Control',
      url: '/utilities/mapping-control',
      icon: 'fa fa-object-ungroup',
      attributes: { id: 'app_sidebar_nav_utilities_view_link' },
      permissions: [PERMISSIONS.VIEW_UTILITIES]
    },
  ],
  roleFunctions: [
    {
      name: 'COST FILE REQUEST',
      url: '/cost-maintenance/cost-file',
      icon: 'fa fa-file-text-o',
      id: 'navigation_bar_cost_file_request_link',
      description: 'Export Blank File to Populate Supplier Cost',
      permissions: [PERMISSIONS.EXECUTE_COST_FILE, PERMISSIONS.EXECUTE_LIMITED_COST_FILE],
      disabled: false
    },
    {
      name: 'COST AUDIT REPORT',
      url: '/reports/cost-audit',
      icon: 'fa fa-newspaper-o',
      id: 'navigation_bar_cost_audit_report_link',
      description: 'Export Report to Audit Supplier Cost',
      permissions: [PERMISSIONS.EXECUTE_COST_AUDIT, PERMISSIONS.EXECUTE_LIMITED_COST_AUDIT],
      disabled: false
    },
    {
      name: 'ADHOC COST CHANGE',
      url: '/cost-maintenance/adhoc-cost-change',
      icon: 'fa fa-tags',
      id: 'navigation_bar_adhoc_cost_change_link',
      description: 'Submit a Supplier Cost Change for Item(s) to Sysco for Review',
      permissions: [PERMISSIONS.INITIATE_ADHOC_COST_CHANGE],
      disabled: false
    },
    {
      name: 'CATALOG COST CHANGE',
      url: '/cost-maintenance/catalog-cost-change',
      icon: 'fa fa-book',
      id: 'navigation_bar_catalog_cost_change_link',
      description: 'Submit a Supplier Price List Change to Sysco for Review',
      permissions: [PERMISSIONS.INITIATE_CATALOG_COST_CHANGE],
      disabled: false
    },
    {
      name: 'AGREEMENT AUDIT',
      url: '/',
      icon: 'fa fa-handshake-o',
      id: 'navigation_bar_agreement_audit_link',
      description: 'Export Report to Audit Negotiated Programs with Supplier',
      permissions: [PERMISSIONS.VIEW_COST_DATA_MANAGEMENT_DASHBOARD],
      disabled: true
    }
  ]
};
