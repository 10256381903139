/**
 * Created by aheshperera
 * 12/3/18
 * */

/**
 * This is used to load the initial state
 */
export default {
  agreementData: {},
  bisFiles: [],
  vendorImportFiles: [],
  agreementImportFiles: [],
  fileTransferDetails: {
    P1: {
      transferSummaryDetails: {},
      fileTransferList: []
    },
    P2: {
      transferSummaryDetails: {},
      fileTransferList: []
    }
  },
  filesLog: {
    vendorPriceFilesImportDetails: {},
  },
  reportsLog: {
    reportDetails: {}
  },
  loader: {
    type: '',
    show: false,
  },
  error: {
    show: false,
    message: ''
  },
  success: {
    show: false,
    message: ''
  },
  user: {
    networkId: '',
    email: '',
    active: false,
    roles: [],
    permissions: [],
    groups: [],
    selectedGroups: [],
    isUserGroupSelectionEnabled: false,
    profile: {
      directReports: [],
      management: {},
      name: '',
      networkId: '',
      userType: '',
      email: '',
      phone: '',
      createdDate: '',
      supervisor: {
        name: ''
      }
    },
  },
  secrets: {
    datadogSecrets: '',
    error: ''
  },
  userManagement: {
    profile: {
      supervisor: {
        isSupervisorChanged: false,
        isVendorAssociationsChanged: false,
      },
      initial: {}
    },
    groups: [],
    directUserGroupAssociationMap: {},
    isUserGroupChanged: false,
    vendorAssociationImportFiles: [],
    supplierDetails: {},
    userAssociations: {},
    existingBusinessCenterContacts: [],
    newUserId: '0'
  },
  selectedUser: {
    networkId: '',
    show: false,
    profile: {}
  },
  isCookie: true,
  users: {
    all: [],
    internal: [],
    supervisors: []
  },
  vendor: {
    data: {
      loading: false,
    },
    filterData: {
      suvcList: {},
      selectedOrder: []
    }
  },
  report: {
    costAuditData: {
      metadata: {
        loading: false,
        businessCenters: [],
        columns: [],
        templates: []
      },
      selection: {
        template: {
          templateHeader: {
            columns: []
          }
        }
      }
    },
    adhocCostData: {
      loading: false,
      suvc: {},
      vendors: [],
      costDataRecords: [],
      invalidCostDataRecords: [],
      itemValidationResponse: {
        loading: false,
        invalidItems: [],
        errorMessage: ''
      },
      fileName: null
    },
    supplementaryData: {
      businessCenters: {
        loading: false,
        data: []
      },
      priceRecordTypes: {
        loading: false,
        data: []
      },
    }
  },
  workspace: {
    helpTopics: {
      data: [],
      loading: false,
      error: {
        show: false,
        message: ''
      },
      selectedHelpTopicDetails: {},
    },
    contactUs: {
      supportRequestTypes: [],
      loading: false,
      error: {
        show: false,
        message: ''
      }
    },
    recentActivity: {
      data: [],
      loading: false,
      error: {
        show: false,
        message: ''
      },
      filteredAttributes: [],
      isFilterRequired: false
    },
    search: {
      isInValid: false,
      searchType: '',
      requestIds: [],
      searchFilters: {
        suvc: null,
        requestStatus: [],
        requestType: [],
        owner: '',
        submitterType: [],
        fromDate: '',
        toDate: '',
        span: ''
      },
      data: [],
      preserve: false,
      loading: false,
      error: {
        show: false,
        message: ''
      }
    },
    selectedRequest: {}
  },
  enterpriseCostData: {
    loading: false,
    sites: [],
    itemHeader: {},
    costData: {},
  },
  utilities: {
    mappingControl: {
      mappingControlData: {},
      isMappingControlResponseReceived: false,
      modifiedBy: ''
    }
  },
  vendorBases: [],
  businessCenters: [],
  sasFDDetails: {
    sasValues: [],
    factors: []
  },
};
