/**
 * Created by aheshperera
 * 12/3/18
 * */

/**
 * React Modules
 */
import { call, put, takeEvery } from 'redux-saga/effects';
/**
 * Custom Modules
 */
import api from '../../externals/bffProxy';
import {
  REQUEST_832_FILES,
  receive832Files,
  REQUEST_VENDOR_ASSOCIATION_FILE_IMPORT,
  receiveVendorAssociationFileImport,
  REQUEST_VENDOR_FILES_PROCESS_STATUS,
  receiveVendorFilesProcessStatus,
  receiveVendorFileReimportUsingVariationReport,
  REQUEST_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT,
  REQUEST_MASS_AGREEMENT_FILE_IMPORT, receiveMassAgreementsImport,
} from '../actions/filesActions';
import {
  receiveErrorIn832FilesFetch, receiveErrorInMassAgreementFileImport,
  receiveErrorInVendorFileImport, receiveErrorInVendorAssociationFileImport,
  receiveErrorInVendorFileLogFetch, receiveErrorInVendorFileProcessStatusFetch,
} from '../actions/errorDialogActions';
import {
  receiveVendorPriceFilesImportLog,
  REQUEST_VENDOR_PRICE_FILES_IMPORT_LOG
} from '../actions/filesLogActions';
import {
  FILE_UPLOAD_ERROR, PRICE_LOAD_ERROR,
  UNABLE_TO_LOAD_832_FILES_DETAILS,
  UNABLE_TO_LOAD_VENDOR_FILE_IMPORT_DETAILS,
} from '../../helpers/messageConstant';

/**
 * Worker Saga: will be fired on REQUEST_832_FILES actions
 */
export function* fetch832Files() {
  try {
    const files = yield call(api.fetch832Files);
    yield put(receive832Files(files));
  } catch (e) {
    yield put(receiveErrorIn832FilesFetch(UNABLE_TO_LOAD_832_FILES_DETAILS));
  }
}

export function* fetchFileProcessStatus({ data }) {
  try {
    const statusDetails = yield call(api.getVendorFileProcessStatus, data);
    yield put(receiveVendorFilesProcessStatus(statusDetails));
  } catch (e) {
    yield put(receiveErrorInVendorFileProcessStatusFetch());
  }
}

export function* requestVendorAssociationFileImport({ data }) {
  try {
    const fileProcessList = yield call(
      api.submitImportFiles, data.type, data.files
    );
    yield put(receiveVendorAssociationFileImport(fileProcessList));
  } catch (e) {
    yield put(receiveErrorInVendorAssociationFileImport(FILE_UPLOAD_ERROR));
  }
}

export function* submitMassAgreementImportFiles({ data }) {
  try {
    const fileProcessList = yield call(
      api.submitImportFiles, data.type, data.files
    );
    yield put(receiveMassAgreementsImport(fileProcessList));
  } catch (e) {
    yield put(receiveErrorInMassAgreementFileImport(FILE_UPLOAD_ERROR));
  }
}

export function* submitVendorPriceReimportUsingVariationReport({ data }) {
  try {
    const fileProcessList = yield call(api.submitVendorPriceReimportUsingVariationReport, data.variationDetails);
    yield put(receiveVendorFileReimportUsingVariationReport(fileProcessList));
  } catch (e) {
    yield put(receiveErrorInVendorFileImport(PRICE_LOAD_ERROR));
  }
}

export function* fetchVendorPriceFilesImportLog({ data }) {
  try {
    const importLog = yield call(
      api.fetchVendorPriceFilesImportLog, data
    );
    yield put(receiveVendorPriceFilesImportLog(importLog));
  } catch (e) {
    yield put(receiveErrorInVendorFileLogFetch(UNABLE_TO_LOAD_VENDOR_FILE_IMPORT_DETAILS));
  }
}
/**
 * Starts fetch832Files on each dispatched `REQUEST_832_FILES` action.
 Allows concurrent fetches of 832 files.
 */
export function* filesSaga() {
  yield takeEvery(REQUEST_832_FILES, fetch832Files);
  yield takeEvery(REQUEST_VENDOR_ASSOCIATION_FILE_IMPORT, requestVendorAssociationFileImport);
  yield takeEvery(REQUEST_MASS_AGREEMENT_FILE_IMPORT, submitMassAgreementImportFiles);
  yield takeEvery(REQUEST_VENDOR_FILES_PROCESS_STATUS, fetchFileProcessStatus);
  yield takeEvery(REQUEST_VENDOR_PRICE_FILES_IMPORT_LOG, fetchVendorPriceFilesImportLog);
  yield takeEvery(REQUEST_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT, submitVendorPriceReimportUsingVariationReport);
}
